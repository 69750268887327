import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavigationBar from './components/Navbar';
import HomePage from './components/HomePage';
import ComingSoon from './components/ComingSoon';

// Use React.lazy to dynamically load the components
const VisionPage = React.lazy(() => import('./components/VisionPage'));
const TeamsPage = React.lazy(() => import('./components/TeamsPage'));
const ModernBarriers = React.lazy(() => import('./components/ModernBarriers'));
const VersePage = React.lazy(() => import('./components/VersePage'));
const TimelinePage = React.lazy(() => import('./components/TimelinePage'));
const ContactUs = React.lazy(() => import('./components/ContactUs'));

function App() {
  return (
    <Router>
      <NavigationBar />
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="" element={<HomePage />} />
          <Route path="/about-us/vision" element={<VisionPage />} />
          <Route path="/about-us/team" element={<TeamsPage />} />
          <Route path="/modern-barriers" element={<ModernBarriers />} />
          <Route path="/our-solutions/verse" element={<VersePage />} />
          <Route path="/our-solutions/timeline" element={<TimelinePage />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="*" element={<ComingSoon />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
