import React from 'react';
import './ComingSoon.css'; // This will reuse similar styles to the HomePage

const ComingSoon = () => {
  return (
    <div className="coming-soon-page">
      <h1>Coming Soon</h1>
    </div>
  );
};

export default ComingSoon;
