import React from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Navbar.css'; // Import the CSS file

const NavigationBar = () => (
  <Navbar expand="lg" className="navbar">
    <Navbar.Brand as={Link} to="/">
      <img
        src={`${process.env.PUBLIC_URL}/versus-logo.png`}
        alt="Versus Foundation"
        height="40" // Adjust the height as needed
        className="d-inline-block align-top"
      />
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="ml-auto"> {/* Aligns nav items to the right */}
        <NavDropdown title="Modern Barriers" id="modern-barriers-dropdown">
          <NavDropdown.Item as={Link} to="/modern-barriers?item=0">Overview</NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/modern-barriers?item=1">Web 2</NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/modern-barriers?item=2">Web 3</NavDropdown.Item>
        </NavDropdown>
        <NavDropdown title="Our Solutions" id="our-solutions-dropdown">
          <NavDropdown.Item as={Link} to="/our-solutions/verse?item=0">Verse</NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/our-solutions/verse?item=1">VersusDB</NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/our-solutions/verse?item=2">Future</NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/our-solutions/timeline">Timeline</NavDropdown.Item>
        </NavDropdown>
        <NavDropdown title="About Us" id="about-us-dropdown">
          <NavDropdown.Item as={Link} to="/about-us/vision?item=0">Our Vision</NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/about-us/vision?item=2">Our Values</NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/about-us/team?item=0">Who We Are</NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/about-us/team?item=1">Our Team</NavDropdown.Item>
        </NavDropdown>
        <NavDropdown title="Docs & Demos" id="docs-demos-dropdown">
        <NavDropdown.Item as="a" href="/Whitepaper/Versus-Whitepaper.pdf" target="_blank" rel="noopener noreferrer">
          Whitepaper
        </NavDropdown.Item>
        <NavDropdown.Item as="a" href="/PitchDeck/Versus-PitchDeck.pdf" target="_blank" rel="noopener noreferrer">
          PitchDeck
        </NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/docs-demos/demos">Demos</NavDropdown.Item>
        </NavDropdown>
        <Nav.Link as={Link} to="/contact-us">Contact Us</Nav.Link>
      </Nav>
    </Navbar.Collapse>
  </Navbar>
);

export default NavigationBar;
