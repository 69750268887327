import React from 'react';
import { useSprings, animated } from 'react-spring';
import styled from 'styled-components';
import './HomePage.css';

const HomePage = () => {
  // Define the number of lines
  const horizontalLines = 5;
  const verticalLines = 8;

  // Define the animation using react-spring for the moving highlight effect
  const [horizontalSprings] = useSprings(horizontalLines, index => ({
    from: { x: 0 },
    to: { x: 100 },
    config: { duration: 8000 },
    delay: index * 500, // Staggered start for each line
    reset: true,
    loop: true,
  }));

  const [verticalSprings] = useSprings(verticalLines, index => ({
    from: { y: 0 },
    to: { y: 100 },
    config: { duration: 8000 },
    delay: index * 500, // Staggered start for each line
    reset: true,
    loop: true,
  }));

  return (
    <Container>
      <GridOverlay>
        {/* Animated Horizontal Lines */}
        {horizontalSprings.map((props, index) => (
          <AnimatedLine
            key={`horizontal-${index}`}
            style={{
              top: `${(index + 1) * (100 / (horizontalLines + 1))}%`,
              background: props.x.to(x => `linear-gradient(
                90deg, 
                rgba(100, 100, 100, 0.3) 0%, 
                rgba(100, 100, 100, 0.3) ${x - 12.5}%, 
                #E4FC22 ${x}%, 
                rgba(100, 100, 100, 0.3) ${x + 12.5}%, 
                rgba(100, 100, 100, 0.3) 100%
              )`),
            }}
          />
        ))}

        {/* Animated Vertical Lines */}
        {verticalSprings.map((props, index) => (
          <AnimatedLineVertical
            key={`vertical-${index}`}
            style={{
              left: `${(index + 1) * (100 / (verticalLines + 1))}%`,
              background: props.y.to(y => `linear-gradient(
                180deg, 
                rgba(100, 100, 100, 0.3) 0%, 
                rgba(100, 100, 100, 0.3) ${y - 12.5}%, 
                #E4FC22 ${y}%, 
                rgba(100, 100, 100, 0.3) ${y + 12.5}%, 
                rgba(100, 100, 100, 0.3) 100%
              )`),
            }}
          />
        ))}
      </GridOverlay>

      {/* Content with logo and title */}
      <TextContainer>
        <TitleContainer>
          <Logo src={`${process.env.PUBLIC_URL}/versus-logo.png`} alt="Versus Logo" />
          <Title>VERSUS</Title>
        </TitleContainer>
        <Subtitle>Building the new internet</Subtitle>
      </TextContainer>
    </Container>
  );
};

// Styled components for styling
const Container = styled.div`
  position: relative;
  height: 100vh;
  background: #000; /* Darker background for contrast */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const GridOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
`;

const AnimatedLine = styled(animated.div)`
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px; /* Thicker lines for better visibility */
  opacity: 0.7;
`;

const AnimatedLineVertical = styled(animated.div)`
  position: absolute;
  top: 0;
  height: 100%;
  width: 2px; /* Thicker vertical lines */
  opacity: 0.7;
`;

const TextContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Slightly transparent dark box for better text readability */
  padding: 20px 40px;
  border-radius: 10px;
  z-index: 2; /* Ensure this is above the lines */
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const Logo = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 10px;
  position: absolute;
  left: -60px;
`;

const Title = styled.h1`
  font-size: 4rem;
  color: #fff;
  margin: 0;
`;

const Subtitle = styled.p`
  font-size: 1.5rem;
  color: #ddd;
  margin-top: 1rem;
`;

export default HomePage;
